import React, { PureComponent } from "react";
import styles from "./MusicianRegistration.module.scss";
import { Formik, Field, ErrorMessage } from "formik";
import Recaptcha from "react-recaptcha";
import { encode } from "../Helpers";
import * as Yup from "yup";

import Button from "../Button";

const initialValues = {
  epost: "",
  navn: "",
  instrument: "",
  sjanger: "",
  kontaktperson: "",
  utdanningsgrad: "",
  telefon: "",
  adresse: "",
  organisasjonsnummer: "",
  link: "",
  by: "",
  bil: "",
  annet: "",
  recaptcha: "",
  samtykke: false
};

const validationScema = Yup.object().shape({
  epost: Yup.string()
    .email("Fyll inn en gyldig epost-adresse")
    .required("Påkrevd felt"),
  navn: Yup.string().required("Påkrevd felt"),
  instrument: Yup.string().required("Påkrevd felt"),
  sjanger: Yup.string().required("Påkrevd felt"),
  kontaktperson: Yup.string(),
  utdanningsgrad: Yup.string().required("Påkrevd felt"),
  telefon: Yup.string().required("Påkrevd felt"),
  adresse: Yup.string().required("Påkrevd felt"),
  organisasjonsnummer: Yup.number(),
  link: Yup.string().url("Må være en gyldig lenke"),
  by: Yup.string(),
  bil: Yup.string(),
  annet: Yup.string(),
  recaptcha: Yup.string().required("Påkrevd felt"),
  samtykke: Yup.boolean()
    .test(
      "samtykke",
      "Du må godkjenne våre vilkår for å registrere deg.",
      value => value === true
    )
    .required("Påkrevd felt")
});

class CustomField extends PureComponent {
  render() {
    const {
      name,
      label,
      type,
      errors,
      touched,
      placeholder,
      required
    } = this.props;

    return (
      <>
        <label htmlFor={name}>
          {label}{" "}
          {required ? <span className={styles.isRequired}>*</span> : null}
          <Field
            className={
              !errors[name] && touched[name]
                ? styles.valid
                : errors[name] && touched[name]
                ? styles.invalid
                : null
            }
            type={type}
            name={name}
            placeholder={placeholder}
            required={required}
          />
        </label>
        <ErrorMessage className={styles.error} name={name} component="div" />
      </>
    );
  }
}

export default class MusicianRegistration extends PureComponent {
  state = {
    formSent: false
  };

  handleFormSent = () => {
    this.setState({ formSent: true });
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <section id="musikere" className={styles.section}>
        <div className={styles.container}>
          <div className={styles.formContainer}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationScema}
              onSubmit={values => {
                fetch("/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                  },
                  body: encode({ "form-name": "musikerskjema", ...values })
                })
                  .then(() => this.handleFormSent())
                  .catch(error => alert(error));
              }}
              render={({
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                values
              }) => {
                return (
                  <form
                    className={styles.form}
                    name="musikerskjema"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                  >
                    <h1 className={styles.title}>Registrer deg som musiker</h1>

                    {!this.state.formSent ? (
                      <>
                        <input type="hidden" name="bot-field" />
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="navn"
                          type="text"
                          label="Navn"
                          required
                        />
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="epost"
                          type="email"
                          label="Epost-adresse"
                          required
                        />
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="instrument"
                          type="text"
                          placeholder="Fiolin, obo, gitar osv"
                          label="Instrument"
                          required
                        />
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="sjanger"
                          type="text"
                          label="Sjanger"
                          required
                        />
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="kontaktperson"
                          type="text"
                          label="Kontaktperson ved kammergrupper"
                        />
                        <div className={styles.radioButtons}>
                          <label>
                            Utdanningsgrad innen musikk{" "}
                            <span className={styles.isRequired}>*</span>{" "}
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="utdanningsgrad"
                              value="Bachelorgrad"
                              required
                            />{" "}
                            Bachelorgrad
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="utdanningsgrad"
                              value="Mastergrad"
                              required
                            />{" "}
                            Mastergrad
                          </label>
                        </div>
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="telefon"
                          type="text"
                          label="Telefonnummer"
                          required
                        />
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="adresse"
                          type="text"
                          label="Folkeregistrert adresse"
                          required
                        />
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="organisasjonsnummer"
                          type="number"
                          label="Organisasjonsnummer"
                        />
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="link"
                          type="url"
                          placeholder="http://"
                          label="Link til opptak eller egen hjemmeside"
                        />
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="by"
                          type="text"
                          label="I hvilken by bor du?"
                        />
                        <div className={styles.radioButtons}>
                          <label>Disponerer du bil?</label>
                          <label>
                            <Field type="radio" name="bil" value="Ja" /> Ja
                          </label>
                          <label>
                            <Field type="radio" name="bil" value="Nei" /> Nei
                          </label>
                        </div>
                        <CustomField
                          errors={errors}
                          touched={touched}
                          name="annet"
                          type="text"
                          label="Annet du ønsker å oppgi"
                        />{" "}
                        <label>
                          <Field type="checkbox" name="samtykke" required /> Ja,
                          jeg godtar at Brillante musikerformidling lagrer
                          informasjon jeg har oppgitt og at de kan ta kontakt
                          via e-post eller telefon ved oppdragsforespørsler
                          eller andre relevante henvendelser.{" "}
                          <span className={styles.isRequired}>*</span>
                        </label>
                        <ErrorMessage
                          className={styles.error}
                          name="samtykke"
                          component="div"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: "1rem"
                          }}
                        >
                          <Recaptcha
                            sitekey="6LfwOIsUAAAAAJHJ-Fj7U-yG3J13R7SbH9FYAs4k"
                            render="explicit"
                            theme="light"
                            verifyCallback={response => {
                              setFieldValue("recaptcha", response);
                            }}
                            onloadCallback={() => {
                              console.log("done loading!");
                            }}
                            hl="no"
                          />
                          <ErrorMessage
                            className={styles.error}
                            name="recaptcha"
                            component="div"
                          />
                        </div>
                        <Button
                          className={styles.submitButton}
                          type="submit"
                          width="14rem"
                        >
                          Registrer
                        </Button>
                      </>
                    ) : (
                      <div style={{ fontSize: "1.2rem" }}>
                        <div
                          style={{ fontWeight: "bold", marginBottom: "1rem" }}
                        >
                          Takk, {values.navn}.
                        </div>
                        Vi har nå mottatt din registrering.
                      </div>
                    )}

                    <div
                      style={{
                        paddingBottom: "9rem"
                      }}
                    />
                  </form>
                );
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}
