import React from "react";
import { Link } from "gatsby";

import styles from "./index.module.scss";

import Layout from "../components/Layout/Layout";
import GenericHelmet from "../components/Helmets/GenericHelmet";
import MusicianRegistration from "../components/Sections/MusicianRegistration";

class IndexPage extends React.Component {
  render() {
    const { location = {} } = this.props;
    return (
      <>
        <GenericHelmet location={location} />
        <Layout location={location}>
          <MusicianRegistration />
        </Layout>
      </>
    );
  }
}

export default IndexPage;
